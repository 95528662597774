import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as Sentry from "@sentry/browser";
import { get, round } from "micro-dash";
import arrayMove from "array-move";
import { normalize } from "normalizr";
import cuid from "cuid";

import config from "../../../../constants/config";
import i18n from "../../../../constants/i18n";

import createApiService from "../../../../network";
import { extractApiErrorMessage } from "../../../../helpers";
import blockTypes from "../../../../constants/blockTypes";

import { block as blockSchema } from "../../../../schemas";
import ValidationError from "../../../../helpers/ValidationError";

import BlockEditModalView from "./BlockEditModalView";
import PhotoProcessingWatcher from "./PhotoProcessingWatcher";

class BlockEditModalContainer extends Component {
    constructor(props) {
        super(props);

        this.api = createApiService(axios);

        if (props.block) {
            this.initialValues = {
                ...props.block,
                // The duration of a speech block needs to be converted to minutes in order to display it correctly
                // in the input field. The input field will not be displayed for other block types and doesn't need
                // to be converted to prevent rounding errors.
                // When submitting, it needs be converted back to milliseconds.
                duration:
                    props.block.type === blockTypes.SPEAKER
                        ? round(props.block.duration / 60000)
                        : props.block.duration,
                photos: props.block.photos.map(photo => ({
                    ...photo,
                    id: `${cuid()}_${photo.id}`,
                    internalId: photo.id
                }))
            };
        }
    }

    deleteBlock = async blockId => {
        if (window.confirm(i18n.generic.deleteItemConfirmationPrompt)) {
            try {
                const response = await this.api.deleteBlock(
                    this.props.ceremonyId,
                    blockId
                );

                this.props.hideModal();
                this.props.showToast({
                    body: get(response, ["data", "message"], ""),
                    title: "Success",
                    themeClass: "is-success"
                });
                this.props.onBlockDeleted(blockId);
            } catch (e) {
                Sentry.captureException(e);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    deletePhotoFromBlock = (photoId, photos, setFieldValue) => {
        setFieldValue("photos", photos.filter(photo => photo.id !== photoId));
    };

    editMedia = blockType => {
        const block = this.props.block;

        if (block.isWalkInBlock || block.isWalkOutBlock) {
            this.props.hideModal();
            this.props.showBlockTypeModal(block.id);
            return;
        }

        if (blockType === blockTypes.AUDIO) {
            this.props.hideModal();
            this.props.showMusicModal(block.id);
        } else if (blockType === blockTypes.VIDEO) {
            this.props.hideModal();
            this.props.showVideoModal(block.id);
        } else {
            return;
        }
    };

    deleteMedia = (blockType, setFieldValue) => {
        const block = this.props.block;

        if (block.isWalkInBlock || block.isWalkOutBlock) {
            // When the media is removed, the block becomes type speaker.
            // Do not change the block.type yet, otherwise the state change will show a
            // different form & undo the changes (change it at onSubmit()).
            // But you can set default values like duration.
            const defaultTime = block.isWalkInBlock
                ? config.oc.walkInTime
                : config.oc.walkOutTime;
            if (block.type === blockTypes.AUDIO) {
                setFieldValue("audioId", null);
                setFieldValue("duration", defaultTime);
            } else if (block.type === blockTypes.VIDEO) {
                setFieldValue("videoId", null);
                setFieldValue("duration", defaultTime);
            }
        }
    };

    onDragEnd = (result, photos, setFieldValue) => {
        const { destination, source } = result;

        if (!destination) {
            return;
        }

        if (destination.index === source.index) {
            return;
        }

        setFieldValue(
            "photos",
            arrayMove(photos, result.source.index, result.destination.index)
        );
    };

    onSubmit = async (values, formikBag) => {
        const imageIds = values.photos.map(photo =>
            parseInt(photo.id.split("_")[1])
        );
        // Duration of a speech block is passed as minutes and needs to be converted to milliseconds.
        // The duration for the other block types are already set in milliseconds.
        const duration =
            this.props.block.type === blockTypes.SPEAKER
                ? parseInt(values.duration) * 60000
                : values.duration;
        const speaker = values.speaker;

        // If an audio or video is deleted from the block, convert the block to type speaker.
        const block = this.props.block;
        if (block.isWalkInBlock || block.isWalkOutBlock) {
            if (block.type === blockTypes.AUDIO && values.audioId === null) {
                block.audioId = null;
                block.type = blockTypes.SPEAKER;
            } else if (
                block.type === blockTypes.VIDEO &&
                values.videoId === null
            ) {
                block.videoId = null;
                block.type = blockTypes.SPEAKER;
            }
        }

        try {
            const {
                data: { data: block }
            } = await this.api.putBlock(this.props.ceremonyId, {
                ...this.props.block,
                imageIds,
                duration,
                speaker
            });

            const { entities } = normalize(block, blockSchema);
            this.props.updateStoreEntities(entities);

            formikBag.setSubmitting(false);
            this.props.hideModal();

            this.props.showToast({
                body: i18n.generic.updatedBlockSuccess,
                title: "Success",
                themeClass: "is-success"
            });
        } catch (e) {
            if (e instanceof ValidationError) {
                formikBag.setErrors(e.errors);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    render() {
        return (
            <Formik
                initialValues={this.initialValues}
                onSubmit={this.onSubmit}
                render={props => (
                    <>
                        <BlockEditModalView
                            deleteBlock={this.deleteBlock}
                            deletePhoto={this.deletePhotoFromBlock}
                            onDragEnd={this.onDragEnd}
                            hideModal={this.props.hideModal}
                            isVisible={true}
                            block={this.props.block}
                            speechPhotoLimit={this.props.speechPhotoLimit}
                            editMedia={this.editMedia}
                            deleteMedia={this.deleteMedia}
                            showPhotoAdjustmentModal={
                                this.props.showPhotoAdjustmentModal
                            }
                            showPhotoUploadModal={
                                this.props.showPhotoUploadModal
                            }
                            {...props}
                        />
                        <PhotoProcessingWatcher
                            block={this.props.block}
                            {...props}
                        />
                    </>
                )}
            />
        );
    }
}

export default withRouter(BlockEditModalContainer);
