import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { get } from "micro-dash";

import styles from "./BlockEditModal.module.scss";

import TimelinePhoto from "../../../TimelinePhoto";
import Input from "../../../Input";
import ShowIf from "../../../ShowIf";
import Warning from "../../../Warning";
import config from "../../../../constants/config";
import i18n from "../../../../constants/i18n";
import blockTypes from "../../../../constants/blockTypes";
import mediaPlaceholder from "../../../../assets/media_placeholder.svg";
import { BlockCommentsContainer } from "../../../../block/comment/BlockCommentsContainer";

const BlockEditModalView = ({
    isEditing,
    isVisible,
    hideModal,
    setFieldValue,
    errors,
    touched,
    values,
    submitForm,
    isSubmitting,
    deleteBlock,
    handleBlur,
    ...props
}) => {
    function isPlaceholderPhoto(photo) {
        return photo.isPlaceholder;
    }

    function displayAddPhotoInBlock(block) {
        if (block.type === blockTypes.AUDIO) {
            return true;
        }

        if (block.type === blockTypes.SPEAKER) {
            return props.speechPhotoLimit > block.photos.length;
        }
    }

    return (
        <div className={`modal ${isVisible ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div className={`modal-card ${styles.modalCard}`}>
                <header className="modal-card-head">
                    {props.block.isWalkInBlock ||
                    props.block.isWalkOutBlock ? null : (
                        <span
                            className={`icon has-text-danger is-medium is-clickable ${styles.deleteButton}`}
                            onClick={() => deleteBlock(values.id)}
                        >
                            <i className="fas fa-trash-alt fa-lg"></i>
                        </span>
                    )}
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.blockEditModal.title}
                    </h2>
                    <button
                        onClick={hideModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <div className={styles.mediaPreviewContainer}>
                        <ShowIf
                            condition={
                                (values.audioId && props.block.audioTrack) ||
                                (values.videoId && props.block.video)
                            }
                        >
                            <div className={styles.mediaPreview}>
                                <div className={styles.mediaPreviewIcon}>
                                    <span className={`icon`}>
                                        <i
                                            className={`fas ${
                                                props.block.audioTrack
                                                    ? "fa-music"
                                                    : "fa-video"
                                            }`}
                                        ></i>
                                    </span>
                                </div>
                                <div>
                                    <span className={styles.mediaPreviewTitle}>
                                        {get(
                                            props.block,
                                            ["audioTrack", "originalFileName"],
                                            ""
                                        )}
                                        {get(
                                            props.block,
                                            ["video", "originalFileName"],
                                            ""
                                        )}
                                    </span>
                                </div>
                                <ShowIf
                                    condition={
                                        props.block.isWalkInBlock ||
                                        props.block.isWalkOutBlock
                                    }
                                >
                                    <div
                                        onClick={() =>
                                            props.deleteMedia(
                                                props.block.type,
                                                setFieldValue
                                            )
                                        }
                                        className={styles.mediaDeleteButton}
                                    >
                                        <span className="icon is-small">
                                            <i className="fas fa-trash"></i>
                                        </span>
                                    </div>
                                </ShowIf>
                                <div>
                                    <div
                                        onClick={() =>
                                            props.editMedia(props.block.type)
                                        }
                                        className={styles.mediaPreviewButton}
                                    >
                                        <span className="icon is-small">
                                            <i className="fas fa-pen"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </ShowIf>
                    </div>

                    <ShowIf condition={props.block.type === blockTypes.AUDIO}>
                        <div className={styles.audioPlayer}>
                            <ShowIf condition={values.audioId}>
                                <audio controls controlsList={"nodownload noplaybackrate"}>
                                    <source
                                        src={get(
                                            props.block,
                                            ["audioTrack", "fileName"],
                                            ""
                                        )}
                                        type="audio/mp3"
                                    />
                                    Your browser does not support the audio
                                    element.
                                </audio>
                            </ShowIf>
                        </div>
                    </ShowIf>

                    <ShowIf condition={props.block.type === blockTypes.SPEAKER}>
                        <div className={styles.durationFieldContainer}>
                            <Input
                                errors={errors}
                                touched={touched}
                                name="speaker"
                                label={i18n.speechModal.speakerName}
                                placeholder={i18n.speechModal.speakerName}
                            />
                            <Input
                                errors={errors}
                                touched={touched}
                                name="duration"
                                label={i18n.blockEditModal.durationFieldLabel}
                                placeholder={
                                    i18n.blockEditModal.durationFieldPlaceholder
                                }
                                type="number"
                            />
                        </div>
                    </ShowIf>

                    <ShowIf
                        condition={
                            props.block.photos && props.block.photos.length
                        }
                    >
                        <ShowIf
                            condition={
                                props.block.type === blockTypes.AUDIO &&
                                props.block.duration > config.oc.musicFadeTime
                            }
                        >
                            <div className={styles.photoListInterval}>
                                {i18n.blockEditModal.photoInterval.replace(
                                    "<INTERVAL>",
                                    (
                                        (props.block.duration -
                                            config.oc.musicFadeTime) /
                                        props.block.photos.length /
                                        1000
                                    ).toFixed(2)
                                )}
                            </div>
                        </ShowIf>
                        <div className={styles.photoList}>
                            <DragDropContext
                                onDragEnd={result =>
                                    props.onDragEnd(
                                        result,
                                        values.photos,
                                        setFieldValue
                                    )
                                }
                            >
                                <Droppable
                                    droppableId="BlockEditPhotos"
                                    direction="horizontal"
                                >
                                    {provided => (
                                        <div
                                            ref={provided.innerRef}
                                            className={
                                                styles.photoListOuterContainer
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.photoListContainer
                                                }
                                            >
                                                {values.photos.map(
                                                    (photo, index) => (
                                                        <Draggable
                                                            draggableId={`${photo.id}-${index}`}
                                                            key={`${photo.id}-${index}`}
                                                            index={index}
                                                        >
                                                            {(
                                                                providedChild,
                                                                snapshot
                                                            ) => (
                                                                <div
                                                                    {...providedChild.draggableProps}
                                                                    ref={
                                                                        providedChild.innerRef
                                                                    }
                                                                    className={`${
                                                                        styles.draggableRoot
                                                                    } ${
                                                                        snapshot.isDragging
                                                                            ? styles.isDragging
                                                                            : ""
                                                                    }`}
                                                                >
                                                                    <ShowIf
                                                                        condition={
                                                                            photo.fileName
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                styles.editButtonContainer
                                                                            }
                                                                            onClick={() =>
                                                                                props.showPhotoAdjustmentModal(
                                                                                    props
                                                                                        .block
                                                                                        .id,
                                                                                    photo.internalId
                                                                                )
                                                                            }
                                                                        >
                                                                            <span
                                                                                className={`icon is-small ${styles.editButton}`}
                                                                            >
                                                                                <i className="fas fa-pen"></i>
                                                                            </span>
                                                                        </div>
                                                                    </ShowIf>
                                                                    <div
                                                                        {...providedChild.dragHandleProps}
                                                                        className={`icon-container is-round is-clickable ${styles.photoHeader}`}
                                                                    >
                                                                        <span
                                                                            className={`icon is-small ${styles.photoHeaderDragHandle}`}
                                                                        >
                                                                            <i className="fas fa-arrows-alt"></i>
                                                                        </span>
                                                                        <span
                                                                            className={
                                                                                styles.photoHeaderIndex
                                                                            }
                                                                        >
                                                                            {index +
                                                                                1}
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.deleteButtonContainer
                                                                        }
                                                                        onClick={() =>
                                                                            props.deletePhoto(
                                                                                photo.id,
                                                                                values.photos,
                                                                                setFieldValue
                                                                            )
                                                                        }
                                                                    >
                                                                        <span
                                                                            className={`icon is-small ${styles.deleteButton}`}
                                                                        >
                                                                            <i className="fas fa-trash-alt"></i>
                                                                        </span>
                                                                    </div>
                                                                    <TimelinePhoto
                                                                        photo={
                                                                            photo
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </ShowIf>

                    <ShowIf
                        condition={
                            props.block.photos &&
                            !props.block.photos.length &&
                            !props.block.video
                        }
                    >
                        <Warning>{i18n.blockEditModal.noPhotosWarning}</Warning>
                    </ShowIf>

                    <ShowIf condition={displayAddPhotoInBlock(props.block)}>
                        <div className={`${styles.addPhotoField}`}>
                            <label className="label">Voeg foto's toe</label>
                            <div
                                onClick={() => props.showPhotoUploadModal()}
                                className={`${styles.mediaObject} ${styles.center}`}
                                style={{
                                    backgroundImage: `url(${mediaPlaceholder})`
                                }}
                            />
                        </div>
                    </ShowIf>
                    <div className="content">
                        <BlockCommentsContainer blockId={props.block.id} ceremonyId={props.block.ceremonyId} />
                    </div>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={submitForm}
                        disabled={isSubmitting}
                        className="button is-primary is-rounded"
                    >
                        {i18n.blockEditModal.submitButtonLabel}
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default BlockEditModalView;
