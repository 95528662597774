/* eslint-disable default-case */

import produce from "immer";
import { combineReducers } from "redux";

import {
    DELETE_BLOCK,
    PUT_BLOCKS,
    REORDER_BLOCKS,
    REPLACE_BLOCKS
} from "../../constants/actionTypes";

const photosById = produce((draft, action) => {
    switch (action.type) {
        case PUT_BLOCKS:
            const blocks = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            blocks.forEach(block => {
                draft[block.id] = block;
            });
            break;

        case REPLACE_BLOCKS:
            let items;
            for (let [key, byId] of Object.entries(action.payload)) {
                items = Object.values(byId);
            }
            draft = {};

            items.forEach(block => {
                draft[block.id] = block;
            });
            return draft;

        case REORDER_BLOCKS:
            action.payload.forEach((blockId, index) => {
                draft[blockId].position = index;
            });
            break;

        case DELETE_BLOCK: {
            delete draft[action.payload];
        }
    }
}, {});

const allPhotos = produce((draft, action) => {
    switch (action.type) {
        case PUT_BLOCKS:
            const blocks = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            blocks.forEach(block => {
                const isAlreadyPresent = draft.includes(block.id);
                if (!isAlreadyPresent) {
                    draft.push(block.id);
                }
            });
            break;

        case DELETE_BLOCK: {
            draft.splice(draft.findIndex(id => id === action.payload), 1);
        }
    }
}, []);

export default combineReducers({
    byId: photosById,
    allIds: allPhotos
});
