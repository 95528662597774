export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const INVALIDATE_USER = "INVALIDATE_USER";
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const PUT_USERS = "PUT_USERS";

export const FETCH_USER_BY_ID_SUCCESS = "FETCH_USER_BY_ID_SUCCESS";
export const FETCH_USER_BY_ID_FAILURE = "FETCH_USER_BY_ID_FAILURE";
export const FETCH_USER_BY_ID_REQUEST = "FETCH_USER_BY_ID_REQUEST";

export const INVALIDATE_ORGANIZATION = "INVALIDATE_ORGANIZATION";
export const FETCH_ORGANIZATION_REQUEST = "FETCH_ORGANIZATION_REQUEST";
export const FETCH_ORGANIZATION_SUCCESS = "FETCH_ORGANIZATION_SUCCESS";
export const FETCH_ORGANIZATION_FAILURE = "FETCH_ORGANIZATION_FAILURE";
export const PUT_ORGANIZATIONS = "PUT_ORGANIZATIONS";
export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";

export const INVALIDATE_TOTAL_DATA_USAGE = "INVALIDATE_TOTAL_DATA_USAGE";
export const FETCH_TOTAL_DATA_USAGE_REQUEST = "FETCH_TOTAL_DATA_USAGE_REQUEST";
export const FETCH_TOTAL_DATA_USAGE_SUCCESS = "FETCH_TOTAL_DATA_USAGE_SUCCESS";
export const FETCH_TOTAL_DATA_USAGE_FAILURE = "FETCH_TOTAL_DATA_USAGE_FAILURE";

export const INVALIDATE_TOTAL_CREMATORY_COUNT =
    "INVALIDATE_TOTAL_CREMATORY_COUNT";
export const FETCH_TOTAL_CREMATORY_COUNT_REQUEST =
    "FETCH_TOTAL_CREMATORY_COUNT_REQUEST";
export const FETCH_TOTAL_CREMATORY_COUNT_SUCCESS =
    "FETCH_TOTAL_CREMATORY_COUNT_SUCCESS";
export const FETCH_TOTAL_CREMATORY_COUNT_FAILURE =
    "FETCH_TOTAL_CREMATORY_COUNT_FAILURE";

export const INVALIDATE_CREMATORY = "INVALIDATE_CREMATORY";
export const FETCH_CREMATORY_REQUEST = "FETCH_CREMATORY_REQUEST";
export const FETCH_CREMATORY_SUCCESS = "FETCH_CREMATORY_SUCCESS";
export const FETCH_CREMATORY_FAILURE = "FETCH_CREMATORY_FAILURE";
export const PUT_CREMATORIES = "PUT_CREMATORIES";
export const DELETE_CREMATORY = "DELETE_CREMATORY";

export const DELETE_ROOM = "DELETE_ROOM";
export const PUT_ROOMS = "PUT_ROOMS";

export const DELETE_DEVICE = "DELETE_DEVICE";
export const PUT_DEVICES = "PUT_DEVICES";

export const DELETE_BLOCK = "DELETE_BLOCK";
export const PUT_BLOCKS = "PUT_BLOCKS";
export const REPLACE_BLOCKS = "REPLACE_BLOCKS";
export const REORDER_BLOCKS = "REORDER_BLOCKS";

export const PUT_AUDIO_TRACKS = "PUT_AUDIO_TRACKS";
export const PUT_PHOTOS = "PUT_PHOTOS";
export const DELETE_PHOTO = "DELETE_PHOTOS";
export const PUT_VIDEOS = "PUT_VIDEOS";

export const INVALIDATE_CEREMONY = "INVALIDATE_CEREMONY";
export const FETCH_CEREMONY_REQUEST = "FETCH_CEREMONY_REQUEST";
export const FETCH_CEREMONY_SUCCESS = "FETCH_CEREMONY_SUCCESS";
export const FETCH_CEREMONY_FAILURE = "FETCH_CEREMONY_FAILURE";
export const PUT_CEREMONIES = "PUT_CEREMONIES";
export const DELETE_CEREMONY = "DELETE_CEREMONY";
export const RELOCATE_CEREMONY = "RELOCATE_CEREMONY";

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";
