import { useEffect, useRef } from "react";

export function useDebounce(callback, delay) {
    const timeoutRef = useRef(null);
    const argumentRef = useRef(null);

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    function debounce(...args) {
        argumentRef.current = args;
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            argumentRef.current = null;
            callback(...args);
        }, delay);
    }

    function pause() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    function resume() {
        if (argumentRef.current) {
            debounce(...argumentRef.current);
        }
    }

    return [debounce, pause, resume];
}
