import { useDeleteBlockComment } from "../hooks/useDeleteBlockComment";
import React from "react";
import i18n from '../../../constants/i18n';

export function BlockCommentsDeleteButton(props) {
    const remove = useDeleteBlockComment(
        props.ceremonyId,
        props.blockId,
        props.blockCommentId,
        () => {
            props.onCommentRemoved(props.commentIndex);
        }
    );

    async function handleRemoveComment() {
        if (window.confirm(i18n.blockComment.deleteConfirmation)) {
            remove.mutate();
        }
    }

    return (
        <button
            onClick={() => handleRemoveComment()}
            className="button is-text has-text-danger is-rounded is-small"
        >
            {i18n.generic.delete}
        </button>
    );
}
