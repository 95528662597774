import { useQuery } from "@tanstack/react-query";
import { baseURL } from "../../constants";
import { getAuthToken } from '../../helpers/tokenStorage';
import {useEffect, useState} from "react";

export function useGetPlaybookUrl(ceremonyId: number) {
    const { data, isLoading } = useQuery(
        [`ceremonies/${ceremonyId}/script`],
        async apiUrl => {
            const response = await fetch(`${baseURL}/api/auth/ceremonies/${ceremonyId}/script`, {
                headers: {
                    responseType: "blob",
                    Authorization: `Bearer ${(getAuthToken() as any).accessToken}`
                }
            });
            const blob = await response.blob();
            return URL.createObjectURL(blob);
        },
        {
            refetchOnWindowFocus: false,
            cacheTime: -1
        }
    );

    return {
        url: data,
        isLoading: isLoading,
    }
}
