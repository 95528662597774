import React, { useEffect, useState } from "react";
import styles from "./BlockCommentsInput.module.scss";
import { BlockCommentsInput } from "./BlockCommentsInput";
import { useReadAllBlockComments } from "./hooks/useReadAllBlockComments";
import ShowIf from "../../components/ShowIf";
import { BlockComment } from "./types/BlockComment";
import roles from "../../constants/roles";
import { useHasOneOfRoles } from "../../core/hooks/useHasOneOfRoles";
import { useIsCeremonyMaster } from "../../core/hooks/useIsCeremonyMaster";
import { BlockCommentsField } from "./BlockCommentsField";
import i18n from "../../constants/i18n";

interface BlockCommentsContainerProps {
    ceremonyId: number;
    blockId: number;
}

export function BlockCommentsContainer(props: BlockCommentsContainerProps) {
    const { isLoading, data } = useReadAllBlockComments(
        props.ceremonyId,
        props.blockId
    );
    const [comments, setComments] = useState<BlockComment[]>([]);
    const isCeremonyMaster = useIsCeremonyMaster(props.ceremonyId);
    const canEditComments =
        useHasOneOfRoles([roles.admin, roles.organization, roles.crematory]) ||
        isCeremonyMaster;

    const handleAddComment = () => {
        setComments([
            ...comments,
            {
                content: "",
                localCreationTimestamp: new Date().getMilliseconds(),
            },
        ]);
    };

    const handleRemoveComment = (indexToRemove: number) => {
        const filteredList = comments.filter(
            (comment, index) => indexToRemove !== index
        );
        setComments(filteredList);
    };

    const handleCommentSaved = (
        indexToUpdate: number,
        updatedComment: BlockComment
    ) => {
        setComments(
            comments.map((comment, index) => {
                return indexToUpdate === index
                    ? Object.assign(comment, updatedComment)
                    : comment;
            })
        );
    };

    useEffect(() => {
        if (data) {
            setComments(data.data);
        }
    }, [data]);

    return (
        <div className={styles.scriptComments}>
            <ShowIf condition={isLoading}>
                <div className={`loader`} />;
            </ShowIf>
            <ShowIf condition={!!comments}>
                <ShowIf condition={canEditComments || comments?.length > 0}>
                    <label className="label">{i18n.blockComment.title}</label>
                </ShowIf>

                <ShowIf condition={!canEditComments}>
                    <BlockCommentsField comments={comments} />
                </ShowIf>
                <ShowIf condition={canEditComments}>
                    {comments.map((comment, index) => (
                        <BlockCommentsInput
                            comment={comment}
                            blockId={props.blockId}
                            ceremonyId={props.ceremonyId}
                            removeComment={handleRemoveComment}
                            commentIndex={index}
                            key={comment.id || comment.localCreationTimestamp}
                            onCommentSaved={handleCommentSaved}
                        />
                    ))}
                    <div className={styles.addButtonContainer}>
                        <button
                            onClick={handleAddComment}
                            className="button is-secondary is-rounded is-small"
                        >
                            {i18n.blockComment.addButton}
                        </button>
                    </div>
                </ShowIf>
            </ShowIf>
        </div>
    );
}
