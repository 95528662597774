import { useMutate } from "../../../core/hooks/useMutate";
import { useDispatch } from "react-redux";
import { showToast } from "../../../actions/toasts";
import i18n from '../../../constants/i18n';

export function useDeleteBlockComment(
    ceremonyId: number,
    blockId: number,
    blockCommentId: number,
    onSuccess?: () => void,
) {
    const dispatch = useDispatch();

    return useMutate<void>(
        `ceremonies/${ceremonyId}/blocks/${blockId}/comments/${blockCommentId}`,
        "DELETE",
        {
            onSuccess: () => {
                dispatch(
                    showToast({
                        body: i18n.blockComment.deleted,
                        title: i18n.generic.success,
                        themeClass: "is-success",
                        preventDismiss: false
                    })
                );
                onSuccess && onSuccess();
            }
        }
    );
}
