import { useCreateBlockComment } from "./useCreateBlockComment";
import { useUpdateBlockComment } from "./useUpdateBlockComment";
import { BlockComment } from '../types/BlockComment';

export function useSaveBlockComment(
    ceremonyId: number,
    blockId: number,
    blockCommentId?: number,
    onSuccess?: (blockComment: BlockComment) => void
) {
    const createComment = useCreateBlockComment(ceremonyId, blockId);
    const updateComment = useUpdateBlockComment(
        ceremonyId,
        blockId,
        blockCommentId
    );

    function handleCommentSave(content: string) {
        if (blockCommentId) {
            updateComment.mutate(
                {
                    content: content
                },
                {
                    onSuccess: data => {
                        onSuccess(data.data);
                    }
                }
            );
        } else {
            createComment.mutate(
                {
                    content: content
                },
                {
                    onSuccess: data => {
                        onSuccess(data.data);
                    }
                }
            );
        }
    }

    return {
        handleCommentSave: handleCommentSave,
        isLoading: createComment.isLoading || updateComment.isLoading
    };
}
