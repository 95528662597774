import { useMutate } from "../../../core/hooks/useMutate";

export function useUpdateBlockComment(
    ceremonyId: number,
    blockId: number,
    blockCommentId: number
) {
    return useMutate(
        `ceremonies/${ceremonyId}/blocks/${blockId}/comments/${blockCommentId}`,
        "PUT"
    );
}
