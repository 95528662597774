import React from "react";
import { BrowserRouter } from "react-router-dom";
import { render } from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";

import App from "./App";
import configureStore from "./configureStore";
import configureNetwork from "./configureNetwork";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios from 'axios';
import { baseURL } from './constants';

if (process.env.NODE_ENV === "production") {
    Sentry.init({ dsn: process.env.REACT_APP_SENTRY_URL });
}

const transportLayer = configureNetwork();
const store = configureStore(undefined, transportLayer);
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            queryFn: async ({ queryKey: [url] }) => {
                if (typeof url === 'string') {
                    const { data } = await axios.get(`${baseURL}/api/auth/${url}`)
                    return data
                }
                throw new Error('Invalid QueryKey')
            },
        },
    },
});

const renderApp = () =>
    render(
        <BrowserRouter>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <App />
                </QueryClientProvider>
            </Provider>
        </BrowserRouter>,
        document.getElementById("root")
    );

if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./App", renderApp);
}

renderApp();
