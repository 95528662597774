import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios from "axios";
import { baseURL } from "../../constants";
import { useDispatch } from "react-redux";
import { showToast } from '../../actions/toasts';
import { extractApiErrorMessage } from '../../helpers';
import i18n from '../../constants/i18n';

export function useMutate<TVALUES>(
    url: string,
    method: "POST" | "PUT" | "DELETE" | "PATCH",
    options: Omit<
        UseMutationOptions<any, any, TVALUES, unknown>,
        "mutationKey"
    > = {}
) {
    const dispatch = useDispatch();

    return useMutation<any, any, TVALUES>([url], {
        mutationFn: async body => {
            const response = await axios.request({
                method: method,
                url: `${baseURL}/api/auth/${url}`,
                data: body
            });

            return response.data;
        },
        onError: error => {
            dispatch(showToast({
                body:
                    extractApiErrorMessage(error) ||
                    i18n.generic.failedToLoadData,
                title: "Error",
                themeClass: "is-danger",
                preventDismiss: false,
            }))
        },
        ...options
    });
}
