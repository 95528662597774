import styles from "./BlockCommentsField.module.scss";
import React from "react";
import { BlockComment } from "./types/BlockComment";

interface BlockCommentsFieldProps {
    comments: BlockComment[];
}

export function BlockCommentsField(props: BlockCommentsFieldProps) {
    return (
        <div className="content">
            {props.comments.map(comment => {
                return (
                    <div>
                        <span>{comment.author}: </span>
                        <blockquote key={comment.id} className={styles.comment}>
                            {comment.content}
                        </blockquote>
                    </div>
                );
            })}
        </div>
    );
}
