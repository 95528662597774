import React from "react";
import { format, isAfter, parse } from "date-fns";

import i18n from "../../../constants/i18n";
import styles from "./TableItem.module.scss";
import StatusIndicator from "../../StatusIndicator";
import ShowIf from "../../ShowIf";
import {PlaybookModal} from "../../../playbook/PlaybookModal";

const TableItemView = ({ ceremony, crematory, onSelect, room, isCeremonyMaster }) => {
    if (!ceremony) {
        return null;
    }

    return (
        <tr>
            <td>
                <StatusIndicator
                    isActive={isAfter(new Date(ceremony.item.date), new Date())}
                    isLocked={ceremony.item.locked}
                />
            </td>
            <td>
                <h2>{ceremony.item.familyName}</h2>
            </td>
            <td>
                <span className="">{crematory ? crematory.item.name : ""}</span>
            </td>
            <td>
                <span className="">
                    {room
                        ? room.name
                        : i18n.ceremonyOverview.roomFilterPlaceholder}
                </span>
            </td>
            <td>
                <span className="">
                    {format(
                        parse(
                            ceremony.item.date,
                            "yyyy-MM-dd HH:mm:ss",
                            new Date()
                        ),
                        "dd/MM/’yy - HH:mm"
                    )}
                </span>
            </td>
            <td>
                <div className="level">
                    <div className="level-item">
                        <span className={`icon ${styles.icon}`}>
                            <i className="fas fa-lg fa-user"></i>
                        </span>
                    </div>
                    <div className="level-item">
                        <span className="">
                            {ceremony.item.ceremonyMaster.name}
                        </span>
                    </div>
                </div>
            </td>
            <td>
                <div className="level">
                    <div className="level-item">
                        <span className={`icon ${styles.icon}`}>
                            <i className="fas fa-lg fa-user"></i>
                        </span>
                    </div>
                    <div className="level-item">
                        <span>{ceremony.item.relatives.length}</span>
                    </div>
                </div>
            </td>
            <ShowIf condition={isCeremonyMaster}>
                <td>
                    <div className="level">
                        <div className="level-item">
                            <PlaybookModal
                                familyName={ceremony.item.familyName}
                                ceremonyId={ceremony.item.id}
                            />
                        </div>
                    </div>
                </td>
            </ShowIf>
            <td>
                <button
                    onClick={() =>
                        onSelect(crematory.item.id, ceremony.item.id)
                    }
                    className="button is-rounded"
                >
                    {i18n.ceremonyOverview.tableItemOpenButtonLabel}
                </button>
            </td>
        </tr>
    );
};

export default TableItemView;
