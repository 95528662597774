// @flow
import React, { Component } from "react";

import { draggableIdPrefixes, droppableIdPrefixes } from "../constants";
import DragDropRootView from "./DragDropRootView";

export const CustomDragDropContext = React.createContext({
    draggingPhotoId: null,
    isDraggingPhoto: false,
    selectedPhotoIds: [],
    showBlockRemovalBox: false,
    togglePhotoSelection: () => {},
    togglePhotoSelectionInGroup: () => {}
});

class DragDropRootContainer extends Component {
    state = {
        draggingPhotoId: null,
        isDraggingPhoto: false,
        selectedPhotoIds: [],
        showBlockRemovalBox: false
    };

    componentDidMount() {
        window.addEventListener("click", this.onWindowClick);
        window.addEventListener("keydown", this.onWindowKeyDown);
        window.addEventListener("touchend", this.onWindowTouchEnd);
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.onWindowClick);
        window.removeEventListener("keydown", this.onWindowKeyDown);
        window.removeEventListener("touchend", this.onWindowTouchEnd);
    }

    togglePhotoSelection = photoId => {
        const selectedPhotoIds = this.state.selectedPhotoIds;
        const wasSelected = selectedPhotoIds.includes(photoId);

        const newPhotoIds = (() => {
            // Task was not previously selected
            // now will be the only selected item
            if (!wasSelected) {
                return [photoId];
            }

            // Task was part of a selected group
            // will now become the only selected item
            if (selectedPhotoIds.length > 1) {
                return [photoId];
            }

            // task was previously selected but not in a group
            // we will now clear the selection
            return [];
        })();

        this.setState({
            selectedPhotoIds: newPhotoIds
        });
    };

    togglePhotoSelectionInGroup = photoId => {
        const selectedPhotoIds = this.state.selectedPhotoIds;
        const index = selectedPhotoIds.indexOf(photoId);

        // if not selected - add it to the selected items
        if (index === -1) {
            this.setState({
                selectedPhotoIds: [...selectedPhotoIds, photoId]
            });
            return;
        }

        // it was previously selected and now needs to be removed from the group
        const shallow = [...selectedPhotoIds];
        shallow.splice(index, 1);

        this.setState({
            selectedPhotoIds: shallow
        });
    };

    unselectAllPhotos = () => {
        this.setState({
            selectedPhotoIds: []
        });
    };

    onWindowKeyDown = event => {
        if (event.defaultPrevented) {
            return;
        }

        if (event.key === "Escape") {
            this.unselectAllPhotos();
        }
    };

    onWindowClick = event => {
        if (event.defaultPrevented) {
            return;
        }
        this.unselectAllPhotos();
    };

    onWindowTouchEnd = event => {
        if (event.defaultPrevented) {
            return;
        }
        this.unselectAllPhotos();
    };

    onBeforeCapture = ({ draggableId }) => {
        const isDraggingBlock = draggableId.includes(draggableIdPrefixes.block);

        if (isDraggingBlock) {
            this.setState({ showBlockRemovalBox: true });
        }
    };

    onDragStart = start => {
        this.props.onDragStateChange(true);
        const originatesFromPhotosDroppable = start.source.droppableId.includes(
            droppableIdPrefixes.photos
        );

        if (originatesFromPhotosDroppable) {
            const id = parseInt(
                start.draggableId.split(draggableIdPrefixes.photo)[1]
            );
            const selected = this.state.selectedPhotoIds.find(
                photoId => photoId === id
            );

            // if dragging an item that is not selected - unselect all items
            if (!selected) {
                this.unselectAllPhotos();
            }

            this.setState({
                draggingPhotoId: start.draggableId,
                isDraggingPhoto: true
            });
        }
    };

    onDragEnd = async result => {
        this.props.onDragStateChange(false);
        this.setState({ showBlockRemovalBox: false });

        const { destination, source, draggableId } = result;

        this.setState({
            draggingPhotoId: null
        });

        if (source.droppableId.includes(droppableIdPrefixes.photos)) {
            this.setState({ isDraggingPhoto: false });
        }

        if (!destination) {
            return;
        }

        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }

        if (
            source.droppableId.includes(
                droppableIdPrefixes.blocks && destination.index === 0
            ) ||
            destination.index === this.props.blocksLength
        ) {
            return;
        }

        if (
            destination.droppableId === droppableIdPrefixes.bin &&
            draggableId.includes(draggableIdPrefixes.block)
        ) {
            const blockId = parseInt(
                draggableId.split(draggableIdPrefixes.block)[1]
            );
            this.props.onBlockRemoved(blockId);
        }

        if (
            source.droppableId.includes(droppableIdPrefixes.photos) &&
            destination.droppableId.includes(droppableIdPrefixes.blockPhoto)
        ) {
            const blockId = parseInt(
                destination.droppableId.split(droppableIdPrefixes.blockPhoto)[1]
            );
            const photoId = parseInt(
                draggableId.split(draggableIdPrefixes.photo)[1]
            );

            this.props.onPhotosAttachedToBlock(
                blockId,
                this.state.selectedPhotoIds.length
                    ? this.state.selectedPhotoIds
                    : [photoId]
            );
        }

        if (
            draggableId.includes(draggableIdPrefixes.block) &&
            destination.droppableId.includes(droppableIdPrefixes.blocks)
        ) {
            this.props.onBlockMoved(source.index, destination.index);
        }
    };

    render() {
        return (
            <CustomDragDropContext.Provider
                value={{
                    draggingPhotoId: this.state.draggingPhotoId,
                    isDraggingPhoto: this.state.isDraggingPhoto,
                    selectedPhotoIds: this.state.selectedPhotoIds,
                    showBlockRemovalBox: this.state.showBlockRemovalBox,
                    togglePhotoSelection: this.togglePhotoSelection,
                    togglePhotoSelectionInGroup: this
                        .togglePhotoSelectionInGroup
                }}
            >
                <DragDropRootView
                    children={this.props.children}
                    onBeforeCapture={this.onBeforeCapture}
                    onDragStart={this.onDragStart}
                    onDragEnd={this.onDragEnd}
                />
            </CustomDragDropContext.Provider>
        );
    }
}

export default DragDropRootContainer;
