import styles from "./RoundIconButton.module.scss";
import React, { PropsWithChildren } from 'react';

interface RoundIconButtonProps {
    onClick: () => void;
}

export function RoundIconButton(props: PropsWithChildren<RoundIconButtonProps>) {
    return <div className={styles.roundIconButton} onClick={props.onClick}>
        {props.children}
    </div>
}
