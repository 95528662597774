import * as Sentry from "@sentry/browser";
import arrayMove from "array-move";
import axios from "axios";
import { endOfDay, isAfter, parse } from "date-fns";
import { get, isUndefined, sortBy } from "micro-dash";
import { normalize } from "normalizr";
import React, { Component } from "react";
import { STATUS } from "react-joyride";
import { withRouter } from "react-router-dom";

import {
    fetchCeremonyAudioTracks,
    fetchCeremonyBlocks,
    fetchCeremonyIfNeeded,
    fetchCeremonyPhotos,
    fetchCeremonyVideos
} from "../../actions/ceremonies";
import { fetchCrematoryIfNeeded } from "../../actions/crematories";
import { showModal } from "../../actions/modals";
import blockTypes from "../../constants/blockTypes";
import i18n from "../../constants/i18n";
import modalTypes from "../../constants/modalTypes";
import { tutorialPath } from "../../constants/storage";
import { extractApiErrorMessage } from "../../helpers";
import createApiService from "../../network";
import {
    block as blockSchema,
    ceremony as ceremonySchema,
    ceremonyWithCrematorium as ceremonyWithCrematoriumSchema
} from "../../schemas";
import TimelineOverviewView from "./TimelineOverviewView";
import routeNames from "../../constants/routeNames";
import { blurredBackgroundEnabledFrom } from "../../constants";

class TimelineOverviewContainer extends Component {
    state = {
        isFetching: true,
        error: null,
        tutorialOptions: {
            run: false,
            steps: [
                {
                    target: ".tutorial-step-1",
                    disableBeacon: true,
                    content: i18n.tutorial.stepUploadingPhotos
                },
                {
                    target: ".tutorial-step-2",
                    disableBeacon: true,
                    content: i18n.tutorial.stepTimelineOverview
                },
                {
                    target: ".tutorial-step-3",
                    disableBeacon: true,
                    content: i18n.tutorial.stepAddingBlocks
                },
                {
                    target: ".tutorial-step-4",
                    disableBeacon: true,
                    content: i18n.tutorial.stepDraggingPhotosToBlock
                },
                {
                    target: ".tutorial-step-5",
                    disableBeacon: true,
                    content: i18n.tutorial.stepBlurredBackground
                },
                {
                    target: ".tutorial-step-6",
                    disableBeacon: true,
                    content: i18n.tutorial.stepFallbackImage
                },
                {
                    target: ".tutorial-step-7",
                    disableBeacon: true,
                    content: i18n.tutorial.stepHelp
                },
                {
                    target: ".tutorial-step-8",
                    disableBeacon: true,
                    content: i18n.tutorial.stepScript
                },
                {
                    target: ".tutorial-step-9",
                    disableBeacon: true,
                    content: i18n.tutorial.stepRelatives
                },
                {
                    target: ".tutorial-step-10",
                    disableBeacon: true,
                    content: i18n.tutorial.stepBlockPreview
                }
            ]
        }
    };

    _isMounted = false;

    constructor(props) {
        super(props);

        this.mainImageUrl = null;
        this.api = createApiService(axios);
        this.blurredBackgroundEnabledDate = new Date(
            blurredBackgroundEnabledFrom
        );
    }

    async addDefaultBlocks(ceremony) {
        const {
            data: { data: walkInBlock }
        } = await this.api.postBlock(ceremony.item.id, {
            type: blockTypes.SPEAKER,
            duration: ceremony.item.waitingTime,
            position: 0
        });

        const {
            data: { data: walkOutBlock }
        } = await this.api.postBlock(ceremony.item.id, {
            type: blockTypes.SPEAKER,
            duration: ceremony.item.waitingTime,
            position: 1
        });

        const { entities: walkInBlockEntities } = normalize(
            walkInBlock,
            blockSchema
        );
        this.props.updateStoreEntities(walkInBlockEntities);

        const { entities: walkOutBlockEntities } = normalize(
            walkOutBlock,
            blockSchema
        );
        this.props.updateStoreEntities(walkOutBlockEntities);
    }

    async componentDidMount() {
        this._isMounted = true;

        this.setState({ isFetching: true });

        await this.fetchData();

        if (this._isMounted) {
            this.setState({ isFetching: false });
        }

        if (this.props.ceremony && this.props.ceremony.item) {
            if (!this.props.isAdmin && this.isCrematoryDeactivated()) {
                this.props.dispatch(
                    showModal({
                        type: modalTypes.DEACTIVATED_CREMATORY,
                        props: { force: true }
                    })
                );
            }

            if (this.areCeremonyAssetsCleanedUp()) {
                this.props.showLockedTimelineModal({
                    areAssetsCleanedUp: true
                });
                return;
            } else if (
                this.props.isEndUser &&
                this.props.ceremony.item.locked
            ) {
                this.props.showLockedTimelineModal({
                    areAssetsCleanedUp: false
                });
                return;
            }

            if (this.shouldShowTutorial()) {
                this.showTutorial();
            }
        }
    }

    componentDidUpdate(prevProps) {
        const previousLocked = get(prevProps, ["ceremony", "item", "locked"]);
        const currentLocked = get(this.props, ["ceremony", "item", "locked"]);

        // Only check locked status if before and after update ceremony was loaded.
        // Avoid situation when prevProps ceremony is still loading and new props already have it loaded thus triggering the lock modal.
        if (
            prevProps.ceremony &&
            prevProps.ceremony.item &&
            this.props.ceremony &&
            this.props.ceremony.item
        ) {
            if (this.props.isEndUser && !previousLocked && currentLocked) {
                this.props.showLockedTimelineModal({
                    areAssetsCleanedUp: false
                });
            }
        }

        if (prevProps.ceremony !== this.props.ceremony) {
            if (
                this.props.ceremony.item.mainImageId &&
                this.props.photos.length > 0
            ) {
                this.mainImageUrl = this.props.photos.find(
                    obj => obj.id === this.props.ceremony.item.mainImageId
                ).fileName;
            } else {
                this.mainImageUrl = null;
            }
        }

        if (prevProps.photos !== this.props.photos && this.props.ceremony) {
            if (
                this.props.ceremony.item.mainImageId &&
                this.props.photos.length > 0
            ) {
                this.mainImageUrl = this.props.photos.find(
                    obj => obj.id === this.props.ceremony.item.mainImageId
                ).fileName;
            } else {
                this.mainImageUrl = null;
            }
        }
    }

    fetchData = async () => {
        const crematoryId = parseInt(this.props.match.params.crematoryID);
        const ceremonyId = parseInt(this.props.match.params.ceremonyID);

        if (this.props.isEndUser && !this.props.ceremony) {
            const response = await this.api.getCeremonyById(ceremonyId);

            const { data } = response.data;
            const { entities } = normalize(data, ceremonyWithCrematoriumSchema);
            this.props.updateStoreEntities(entities);
        }

        if (!this.props.isEndUser && !this.props.crematory) {
            await this.props.dispatch(fetchCrematoryIfNeeded(crematoryId));
        }

        if (!this.props.isEndUser && !this.props.ceremony) {
            await this.props.dispatch(fetchCeremonyIfNeeded(ceremonyId));
        }

        if (this.props.ceremony) {
            await this.props.dispatch(fetchCeremonyPhotos(this.props.ceremony));
            await this.props.dispatch(fetchCeremonyBlocks(this.props.ceremony));

            /**
             * Sometimes blocks aren't created correctly... @see PCIOC-55 in JIRA.
             * As no errors where found during debugging, this is the 'solution' that you got. Deal with it ;)
             */
            if (this.props.blocks.length === 0) {
                await this.addDefaultBlocks(this.props.ceremony);
            }

            await this.props.dispatch(
                fetchCeremonyAudioTracks(this.props.ceremony)
            );
            await this.props.dispatch(fetchCeremonyVideos(this.props.ceremony));

            if (this.props.ceremony.item.mainImageId) {
                this.mainImageUrl = this.props.photos.find(
                    obj => obj.id === this.props.ceremony.item.mainImageId
                ).fileName;
            }
        }
    };

    isCrematoryDeactivated = () => {
        return this.props.crematory.item.active === 0;
    };

    areCeremonyAssetsCleanedUp = () => {
        return isAfter(
            new Date(),
            endOfDay(
                parse(
                    this.props.ceremony.item.date,
                    "yyyy-MM-dd HH:mm:ss",
                    new Date()
                )
            )
        );
    };

    shouldShowTutorial = () => {
        return Boolean(localStorage.getItem(tutorialPath)) === false;
    };

    showTutorial = () => {
        if (this._isMounted) {
            this.setState({
                tutorialOptions: { ...this.state.tutorialOptions, run: true }
            });
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    onEditBlock = block => {
        this.props.dispatch(
            showModal({
                type: modalTypes.BLOCK_EDIT,
                props: {
                    ceremonyId: this.props.match.params.ceremonyID,
                    blockId: block.id,
                    isWalkInBlock: block.isWalkInBlock,
                    isWalkOutBlock: block.isWalkOutBlock
                }
            })
        );
    };

    onAddButtonClick = position => {
        this.props.dispatch(
            showModal({
                type: this.props.hasExceededAmountOfBlocks
                    ? modalTypes.BLOCK_AMOUNT_WARNING
                    : this.props.hasExceededCeremonyDuration
                    ? modalTypes.DURATION_WARNING
                    : modalTypes.BLOCK_TYPE,
                props: {
                    ceremonyId: this.props.match.params.ceremonyID,
                    position
                }
            })
        );
    };

    onRelativesButtonClick = () => {
        this.props.dispatch(
            showModal({
                type: modalTypes.RELATIVES,
                props: { ceremonyId: this.props.match.params.ceremonyID }
            })
        );
    };

    onWalkInOrOutBlockMediaPlaceholderClick = block => {
        this.props.dispatch(
            showModal({
                type: modalTypes.BLOCK_TYPE,
                props: {
                    ceremonyId: this.props.match.params.ceremonyID,
                    blockId: block.id,
                    isWalkInBlock: block.isWalkInBlock,
                    isWalkOutBlock: block.isWalkOutBlock,
                    hideSpeechType: true
                }
            })
        );
    };

    onPreviewButtonClick = () => {
        this.props.dispatch(
            showModal({
                type: modalTypes.BLOCK_PROCESSING,
                props: { ceremonyId: this.props.match.params.ceremonyID }
            })
        );
    };

    onIntroCallback = data => {
        const { status } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            this.setState({
                tutorialOptions: {
                    ...this.state.tutorialOptions,
                    run: false
                }
            });
            localStorage.setItem(tutorialPath, 1);
        }
    };

    onStartIntro = () => {
        this.setState({
            tutorialOptions: {
                ...this.state.tutorialOptions,
                run: true
            }
        });
    };

    onBlurredBackgroundClick = async ceremony => {
        await this.api.changeBackground(
            ceremony.item.id,
            !ceremony.item.useBlurredPhotoBackground
        );
        this.setState({ isFetching: true });

        const response = await this.api.getCeremonyById(ceremony.item.id);

        const { data } = response.data;
        const { entities } = normalize(data, ceremonySchema);
        this.props.updateStoreEntities(entities);
        await this.props.dispatch(fetchCeremonyPhotos(this.props.ceremony));

        this.setState({ isFetching: false });
    };

    onPhotosAttachedToBlock = async (blockId, photoIds) => {
        try {
            const block = this.props.blocks.find(block => block.id === blockId);

            const blockPhotoLimit = this.props.ceremony.item.numberPhotos;
            const currentBlockPhotos = block.imageIds;

            if (
                block.type === blockTypes.SPEAKER &&
                block.id !== this.props.walkInBlockId &&
                block.id !== this.props.walkOutBlockId
            ) {
                if (currentBlockPhotos.length === blockPhotoLimit) {
                    this.props.showToast({
                        body:
                            i18n.timelineOverview.exceededSpeechBlockPhotoLimit,
                        title: "Error",
                        themeClass: "is-danger"
                    });
                    return;
                } else if (
                    currentBlockPhotos.length + photoIds.length >
                    blockPhotoLimit
                ) {
                    this.props.showToast({
                        body:
                            photoIds.length === 1
                                ? i18n.timelineOverview
                                      .multiDragSingluarSpeechBlockPhotoLimit
                                : i18n.timelineOverview.multiDragPluralSpeechBlockPhotoLimit.replace(
                                      "<NUMBER>",
                                      blockPhotoLimit
                                  ),
                        title: "Error",
                        themeClass: "is-danger"
                    });
                    return;
                }
            }

            const updatedBlock = {
                ...block,
                imageIds: [...block.imageIds, ...photoIds]
            };

            this.props.updateStoreEntities({
                blocks: { [updatedBlock.id]: updatedBlock }
            });

            const {
                data: { data: responseBlock }
            } = await this.api.putBlock(
                this.props.match.params.ceremonyID,
                updatedBlock
            );

            const { entities } = normalize(responseBlock, blockSchema);
            this.props.updateStoreEntities(entities);

            this.props.showToast({
                body: i18n.timelineOverview.attachPhotoToBlockSuccess,
                title: "Success",
                themeClass: "is-success"
            });
        } catch (e) {
            console.error(e);

            // TODO: Revert attaching the photo to the block.
            this.props.showToast({
                body: extractApiErrorMessage(e),
                title: "Error",
                themeClass: "is-danger"
            });
        }
    };

    onBlockMoved = async (sourceIndex, destinationIndex) => {
        const blocks = sortBy(this.props.blocks, block => block.position);
        const updatedBlocks = arrayMove(
            blocks,
            sourceIndex,
            destinationIndex
        ).map(block => block.id);
        this.props.reorderBlocks(updatedBlocks);
        return updatedBlocks;
    };

    persistBlockMove = async updatedBlocks => {
        this.refreshTimelineBlocks(updatedBlocks);
    };

    onBlockRemoved = async blockId => {
        if (window.confirm(i18n.generic.deleteItemConfirmationPrompt)) {
            try {
                this.props.onBlockDeleted(blockId);

                const response = await this.api.deleteBlock(
                    this.props.ceremony.item.id,
                    blockId
                );

                this.props.showToast({
                    body: get(response, ["data", "message"], ""),
                    title: "Success",
                    themeClass: "is-success"
                });
            } catch (e) {
                Sentry.captureException(e);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }

            this.refreshTimelineBlocks();
        }
    };

    onBackToOverviewButtonClick = () => {
        if (this.props.isEndUser) {
            this.props.history.push(`${routeNames.selectCeremony}`);
        }

        // This check is needed since a location or organization user can land on this page through external ceremonies.
        // In case the user is not an owner of the current Location, we redirect him back to the external cermonies page.
        if (this.props.isCrematory || this.props.isOrganization) {
            const crematoryID = this.props.match.params.crematoryID;
            const isCrematoryIDPresent = this.props.user.profile.locations.some(
                location => {
                    return location.id == crematoryID;
                }
            );

            if (isCrematoryIDPresent) {
                this.props.history.push(
                    `${routeNames.locationDetailsPaginated}`
                        .replace(":crematoryID", crematoryID)
                        .replace(
                            ":pageNumber",
                            sessionStorage.getItem("ceremonyOverviewPage") !==
                                "undefined"
                                ? sessionStorage.getItem("ceremonyOverviewPage")
                                : 1
                        )
                );
            } else {
                this.props.history.push(
                    `${routeNames.invitedCeremonies}`.replace(":pageNumber", 1)
                );
            }
        }

        if (this.props.isAdmin) {
            this.props.history.push(
                `${routeNames.locationDetailsPaginated}`
                    .replace(
                        ":crematoryID",
                        this.props.match.params.crematoryID
                    )
                    .replace(
                        ":pageNumber",
                        sessionStorage.getItem("ceremonyOverviewPage") !==
                            "undefined"
                            ? sessionStorage.getItem("ceremonyOverviewPage")
                            : 1
                    )
            );
        }
    };

    /**
     * Reresh timeline blocks.
     *
     * Everytime that a block is: added, removed or sorted.
     * Refresh the blocks to keep the data in sync (otherwise the user may see outdated data
     * when using different browser tabs).
     *
     * @param blockIds|null       List of current or preferred order blockIds. If no ID's are given, the positions are server-side regenerated.
     * @returns {Promise<void>}
     */
    refreshTimelineBlocks = async blockIds => {
        // Get block ID's from browser.
        if (isUndefined(blockIds)) {
            blockIds = sortBy(this.props.blocks, block => block.position).map(
                block => block.id
            );
        }

        try {
            // Submit the new positions of the blocks.
            const {
                data: { data: blocks }
            } = await this.api.reorderBlocks(
                this.props.ceremony.item.id,
                blockIds
            );

            // Check if the submitted block positions matches the blocks from the server.
            let hasDeletedBlocks = false;
            blockIds.forEach(blockId => {
                let block = blocks.find(
                    block => block.id === blockId
                );
                if (isUndefined(block)) {
                    this.props.onBlockDeleted(blockId);
                    hasDeletedBlocks = true;
                }
            });

            // If the positions don't match, inform the user that the browser data is outdated since last update.
            if (
                hasDeletedBlocks ||
                blockIds.length !== blocks.length
            ) {
                this.props.showToast({
                    body: i18n.timelineOverview.blocksModifiedSinceLastUpdate,
                    title: "Warning",
                    themeClass: "is-warning"
                });
                await this.reloadProject();
                return;
            }

            // Store the data.
            const { entities } = normalize(blocks, [blockSchema]);
            this.props.updateStoreEntities(entities);
            this.props.showToast({
                body: i18n.timelineOverview.moveBlocksSuccess,
                title: "Succes",
                themeClass: "is-success"
            });
        } catch (e) {
            if (e.response && e.response.status === 409) {
                this.props.showToast({
                    body: i18n.timelineOverview.blocksModifiedSinceLastUpdate,
                    title: "Warning",
                    themeClass: "is-warning"
                });
                await this.reloadProject();
            } else {
                this.props.showToast({
                    body: i18n.generic.error,
                    title: "Warning",
                    themeClass: "is-warning"
                });
            }
        }
    };

    async reloadProject() {
        await this.props.dispatch(fetchCeremonyPhotos(this.props.ceremony));

        await this.props.dispatch(
            fetchCeremonyAudioTracks(this.props.ceremony)
        );
        await this.props.dispatch(fetchCeremonyVideos(this.props.ceremony));

        if (this.props.ceremony.item.mainImageId) {
            this.mainImageUrl = this.props.photos.find(
                obj => obj.id === this.props.ceremony.item.mainImageId
            ).fileName;
        }

        const blocks = await this.api.getCeremonyBlocks(this.props.match.params.ceremonyID);
        const { entities } = normalize(blocks.data.data, [blockSchema]);
        this.props.replaceBlocks(entities);
    }

    deletePhoto = async photoId => {
        try {
            await this.api.deletePhoto(this.props.ceremony.item.id, photoId);

            this.props.onPhotoDeleted(photoId);
            this.props.showToast({
                body: i18n.timelineOverview.photoDeleted,
                title: "Success",
                themeClass: "is-success"
            });
        } catch (e) {
            if (e.response && e.response.status === 409) {
                this.props.showToast({
                    body: i18n.timelineOverview.photoInBlockError,
                    title: "Warning",
                    themeClass: "is-warning"
                });
            } else {
                this.props.showToast({
                    body: i18n.generic.error,
                    title: "Warning",
                    themeClass: "is-warning"
                });
            }
        }
    };

    render() {
        const {
            blocks,
            ceremony,
            crematory,
            isEndUser,
            isAdmin,
            dispatch,
            onMainImageButtonClick
        } = this.props;

        if (!crematory || !ceremony) {
            return null;
        }

        return (
            <TimelineOverviewView
                blocks={blocks}
                ceremony={ceremony}
                ceremonyMainImageUrl={this.mainImageUrl}
                isEndUser={isEndUser}
                isAdmin={isAdmin}
                isFetching={this.state.isFetching}
                onAddButtonClick={this.onAddButtonClick}
                onBlockMoved={this.onBlockMoved}
                persistBlockMove={this.persistBlockMove}
                onBlockRemoved={this.onBlockRemoved}
                onPhotosAttachedToBlock={this.onPhotosAttachedToBlock}
                onEditBlock={this.onEditBlock}
                onIntroCallback={this.onIntroCallback}
                onMainImageButtonClick={onMainImageButtonClick}
                onPreviewButtonClick={this.onPreviewButtonClick}
                onRelativesButtonClick={this.onRelativesButtonClick}
                onBackToOverviewButtonClick={this.onBackToOverviewButtonClick}
                onBlurredBackgroundClick={this.onBlurredBackgroundClick}
                blurredBackgroundEnabledCheck={
                    new Date(this.props.ceremony.item.createdAt) >
                    this.blurredBackgroundEnabledDate
                }
                onStartIntro={this.onStartIntro}
                deletePhoto={this.deletePhoto}
                user={this.props.user}
                onWalkInOrOutBlockMediaPlaceholderClick={
                    this.onWalkInOrOutBlockMediaPlaceholderClick
                }
                showPhotoUploadModal={() =>
                    dispatch(
                        showModal({
                            type: modalTypes.PHOTO_UPLOAD,
                            props: {
                                ceremonyId: this.props.match.params.ceremonyID
                            }
                        })
                    )
                }
                showBlockTypeModal={() =>
                    dispatch(
                        showModal({
                            type: modalTypes.BLOCK_TYPE,
                            props: {
                                ceremonyId: this.props.match.params.ceremonyID
                            }
                        })
                    )
                }
                showLibraryPhotoAdjustementModal={() =>
                    dispatch(
                        showModal({
                            type: modalTypes.LIBRARY_PHOTO_ADJUSTMENT,
                            props: {
                                ceremonyId: this.props.match.params.ceremonyID
                            }
                        })
                    )
                }
                tutorialOptions={this.state.tutorialOptions}
            />
        );
    }
}

export default withRouter(TimelineOverviewContainer);
