import React, { Component } from "react";
import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import { normalize } from "normalizr";
import * as Sentry from "@sentry/browser";

import i18n from "../../../../constants/i18n";
import blockTypes from "../../../../constants/blockTypes";
import createApiService from "../../../../network";
import ValidationError from "../../../../helpers/ValidationError";
import { extractApiErrorMessage } from "../../../../helpers";
import { block as blockSchema } from "../../../../schemas";

import SpeechModalView from "./SpeechModalView";
import { isUndefined, sortBy } from "micro-dash";

class SpeechModalContainer extends Component {
    initialValues = {
        duration: 10,
        type: "speaker",
    };

    constructor(props) {
        super(props);

        this.api = createApiService(axios);
    }

    validationSchema = Yup.object().shape({
        duration: Yup.string(),
    });

    onSubmit = async (values, formikBag) => {
        if (
            this.props.isEditing &&
            (this.props.block.isWalkInBlock || this.props.block.isWalkOutBlock)
        ) {
            return this.onUpdateWalkInOrOutBlock(values, formikBag);
        }

        try {
            const newValues = {
                ...values,
                speaker: values.speaker,
                duration: parseInt(values.duration) * 60000,
                position: this.props.position,
            };

            await this.api.postBlock(this.props.ceremonyId, newValues);

            const blocks = await this.api.getCeremonyBlocks(
                this.props.ceremonyId
            );

            const { entities: newBlockEntities } = normalize(blocks.data.data, [
                blockSchema,
            ]);
            this.props.updateStoreEntities(newBlockEntities);

            formikBag.setSubmitting(false);
            this.props.hideModal();
            this.props.showToast({
                body: i18n.generic.createBlockSuccess,
                title: "Success",
                themeClass: "is-success",
            });
        } catch (e) {
            if (e instanceof ValidationError) {
                formikBag.setErrors(e.errors);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger",
                });
            }
        }
    };

    onUpdateWalkInOrOutBlock = async (values, formikBag) => {
        const { ceremony } = this.props;
        const initialBlock = this.props.block;

        try {
            const position = this.props.isWalkInBlock
                ? 0
                : this.props.blocks.length - 1;

            const {
                data: { data: block },
            } = await this.api.putBlock(ceremony.item.id, {
                ...this.props.block,
                type: blockTypes.SPEAKER,
                duration: parseInt(values.duration) * 60000,
                audioId: null,
                videoId: null,
                imageIds: initialBlock.imageIds,
                position,
            });

            await this.refreshTimelineBlocks();

            formikBag.setSubmitting(false);
            this.props.showToast({
                body: i18n.generic.updatedBlockSuccess,
                title: "Success",
                themeClass: "is-success",
            });
            this.props.hideModal();
        } catch (e) {
            if (e instanceof ValidationError) {
                this.mapValidationErrors(e, formikBag);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger",
                });
            }
        }
    };

    /**
     * Reresh timeline blocks.
     *
     * @TODO Refactor this at a later moment.
     * This a copy-paste from TimelineOverviewContainer.js
     * Find a way to call that method, using ref, dispatch or something similar.
     * Passing this method using props is not feasible, because this component is used in a lot of places.
     *
     * Everytime that a block is: added, removed or sorted.
     * Refresh the blocks to keep the data in sync (otherwise the user may see outdated data
     * when using different browser tabs).
     *
     * @param blockIds|null       List of current or preferred order blockIds. If no ID's are given, the positions are server-side regenerated.
     * @returns {Promise<void>}
     */
    refreshTimelineBlocks = async (blockIds) => {
        // Get block ID's from browser.
        if (isUndefined(blockIds)) {
            blockIds = sortBy(this.props.blocks, (block) => block.position).map(
                (block) => block.id
            );
        }

        // Submit the new positions of the blocks.
        const {
            data: { data: blocks },
        } = await this.api.reorderBlocks(this.props.ceremony.item.id, blockIds);

        // Check if the submitted block positions matches the blocks from the server.
        let hasDeletedBlocks = false;
        blockIds.forEach((blockId) => {
            let block = blocks.find((block) => block.id === blockId);
            if (isUndefined(block)) {
                this.props.onBlockDeleted(blockId);
                hasDeletedBlocks = true;
            }
        });

        // If the positions doesn't match, inform the user that the browser data is outdated since last update.
        if (hasDeletedBlocks || blockIds.length !== blocks.length) {
            this.props.showToast({
                body: i18n.timelineOverview.blocksModifiedSinceLastUpdate,
                title: "Warning",
                themeClass: "is-warning",
            });
        }

        // Store the data.
        const { entities } = normalize(blocks, [blockSchema]);
        this.props.updateStoreEntities(entities);
    };

    render() {
        return (
            <Formik
                initialValues={this.initialValues}
                onSubmit={this.onSubmit}
                validationSchema={this.validationSchema}
                render={(props) => (
                    <SpeechModalView
                        hideModal={this.props.hideModal}
                        isVisible={true}
                        {...props}
                    />
                )}
            />
        );
    }
}

export default SpeechModalContainer;
