import i18n from "../constants/i18n";
import React from "react";
import { useGetPlaybookUrl } from "./hooks/useGetPlaybookUrl";
import styles from "./PlaybookModalContent.module.scss";

interface PlaybookModalContentProps {
    ceremonyId: number;
    familyName: string;
}

export function PlaybookModalContent(props: PlaybookModalContentProps) {
    const { url, isLoading } = useGetPlaybookUrl(props.ceremonyId);

    if (isLoading) {
        return (
            <section className="modal-card-body">
                <div className={`loader ${styles.loader}`} />
            </section>
        );
    }

    if (!url) {
        return null;
    }

    return (
        <>
            <section className="modal-card-body">
                <object
                    data={url}
                    type="application/pdf"
                    style={{
                        width: "100%",
                        height: "100%"
                    }}
                    aria-label={i18n.generic.script}
                />
            </section>
            <footer className="modal-card-foot">
                <a
                    href={url}
                    className="button is-primary"
                    download={`${props.familyName} - ${i18n.generic.script}.pdf`}
                >
                    Download
                </a>
            </footer>
        </>
    );
}
