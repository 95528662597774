import { connect } from "react-redux";
import { get } from "micro-dash";

import { fetchCrematoryIfNeeded } from "../../../actions/crematories";
import TableItemView from "./TableItemView";

const mapStateToProps = (state, ownProps) => {
    const ceremony = get(
        state,
        ["entities", "ceremonies", "byId", ownProps.ceremonyId],
        null
    );
    const crematoryId = get(ceremony, ["item", "crematoryId"]);
    const crematory = get(
        state,
        ["entities", "crematories", "byId", crematoryId],
        null
    );

    const currentUserId = state.user.profile.id;
    const ceremonyMasterId = ceremony.item.ceremonyMaster.id

    return {
        ceremony,
        crematory,
        currentUserId,
        room: get(
            state,
            ["entities", "rooms", "byId", get(ceremony, ["item", "room"])],
            null
        ),
        isCeremonyMaster: currentUserId === ceremonyMasterId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchDataIfNeeded: () => {
            dispatch(fetchCrematoryIfNeeded());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TableItemView);
