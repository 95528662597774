import React, { useState } from "react";
import { Form } from "formik";
import { times } from "micro-dash";

import styles from "./CeremonyModal.module.scss";
import i18n from "../../../../constants/i18n";

import Input from "../../../Input";
import Checkbox from "../../../Checkbox";
import DateField from "../../../DateField";
import Select from "../../../Select";
import SelectStringBased from "../../../SelectStringBased";
import ShowIf from "../../../ShowIf";
import { FormNotice } from "../../../FormNotice/FormNotice";
import ShowIfView from "../../../ShowIf";

const IntervalSelect = ({
    errors,
    touched,
    setFieldValue,
    handleBlur,
    value,
    name,
    label,
    placeholder,
    interval,
    intervalCount,
    intervalStart = 0
}) => {
    const options = times(
        intervalCount,
        num => num * interval + intervalStart
    ).map(number => ({
        id: number,
        name: `${number} minuten`
    }));

    return (
        <Select
            errors={errors}
            touched={touched}
            name={name}
            label={label}
            placeholder={placeholder}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            value={value}
            items={options}
        />
    );
};

const CrematoryModalView = ({
    isEditing,
    isVisible,
    hideModal,
    setFieldError,
    setFieldValue,
    errors,
    touched,
    values,
    submitForm,
    isValid,
    isSubmitting,
    deleteCeremony,
    handleBlur,
    rooms,
    types,
    organizationCrematories,
    isDeleteDisabled,
    ...props
}) => {
    let selectedCrematory = null;
    let roomOptions = rooms;

    if (organizationCrematories.length > 0) {
        selectedCrematory = organizationCrematories.find(
            crematory => crematory.id === values.crematoryId
        );
        roomOptions = selectedCrematory ? selectedCrematory.rooms : [];
    }

    const selectedRoom =
        values.room && roomOptions.find(room => room.id === values.room);
    const [originalCrematoryId] = useState(values.crematoryId);
    const [originalRoom] = useState(selectedRoom);
    const [originalLivestream] = useState(values.liveStream);

    // For organization users. If a different crematory is selected, then the selected room needs to be reset.
    if (!selectedRoom) {
        values.room = "";
    }

    // React Formikbag component doesn't like NULL values.
    // Cast it to empty string as suggested, otherwise a JS exception will be thrown.
    values.serviceCode = values.serviceCode === null ? "" : values.serviceCode;

    return (
        <div className={`modal ${isVisible ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {isEditing
                            ? i18n.ceremonyModal.titleEditMode
                            : i18n.ceremonyModal.titleCreateMode}
                    </h2>
                    <button
                        onClick={hideModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <Form>
                        <Input
                            errors={errors}
                            touched={touched}
                            name="familyName"
                            label={i18n.ceremonyModal.familyNameLabel}
                            placeholder={
                                i18n.ceremonyModal.familyNamePlaceholder
                            }
                        />
                        {/*If the user belongs to an organization then show a dropdownbox for crematory.*/}
                        <ShowIf condition={organizationCrematories.length > 0}>
                            <Select
                                errors={errors}
                                touched={touched}
                                name="crematoryId"
                                label={i18n.ceremonyModal.crematoryLabel}
                                placeholder={
                                    i18n.ceremonyModal.crematoryPlaceholder
                                }
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                value={values.crematoryId}
                                items={organizationCrematories}
                            />
                        </ShowIf>
                        <Select
                            errors={errors}
                            touched={touched}
                            name="room"
                            label={i18n.ceremonyModal.roomLabel}
                            placeholder={i18n.ceremonyModal.roomPlaceholder}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            disabled={
                                organizationCrematories.length > 0 &&
                                !selectedCrematory
                            }
                            value={values.room}
                            items={roomOptions}
                            notice={
                                values.liveStream &&
                                originalRoom &&
                                originalRoom.livestreaming &&
                                (!selectedRoom || !selectedRoom.livestreaming)
                                    ? i18n.ceremonyModal
                                          .streamingCeremonyMovedToAnotherRoomNotice
                                    : null
                            }
                        />
                        <DateField
                            errors={errors}
                            label={i18n.ceremonyModal.dateLabel}
                            name="date"
                            setFieldValue={setFieldValue}
                            touched={touched}
                            values={values}
                        />
                        <IntervalSelect
                            errors={errors}
                            touched={touched}
                            name="totalTime"
                            label={i18n.ceremonyModal.durationLabel}
                            placeholder={i18n.ceremonyModal.durationPlaceholder}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            value={values.totalTime}
                            interval={5}
                            intervalCount={31}
                            intervalStart={30}
                        />
                        <SelectStringBased
                            errors={errors}
                            touched={touched}
                            name="type"
                            label={i18n.ceremonyModal.typeLabel}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            value={values.type}
                            items={types}
                        />
                        <Input
                            errors={errors}
                            touched={touched}
                            name="numberPhotos"
                            label={
                                i18n.ceremonyModal
                                    .allowedPhotosOnSpeechBlockLabel
                            }
                            placeholder={
                                i18n.ceremonyModal
                                    .allowedPhotosOnSpeechBlockPlaceholder
                            }
                        />
                        <Input
                            errors={errors}
                            touched={touched}
                            name="serviceCode"
                            label={i18n.ceremonyModal.serviceCodeLabel}
                            placeholder=""
                            type="text"
                        />
                        <label className="label">
                            {i18n.ceremonyModal.ceremonyMasterLabel}
                        </label>
                        <Input
                            errors={errors}
                            touched={touched}
                            name="ceremonyMaster.name"
                            label=""
                            placeholder={i18n.generic.name}
                            smallerMargin
                        />
                        <Input
                            errors={errors}
                            touched={touched}
                            name="ceremonyMaster.email"
                            label=""
                            placeholder={i18n.generic.email}
                            type="email"
                            smallerMargin
                        />
                        <Input
                            errors={errors}
                            touched={touched}
                            name="ceremonyMaster.phone"
                            label=""
                            placeholder={i18n.generic.phoneNumber}
                            type="phone"
                        />
                        <label className="label">
                            {i18n.generic.recording}
                        </label>
                        <div className="field">
                            <div className={styles.checkboxContainer}>
                                <Checkbox
                                    errors={errors}
                                    touched={touched}
                                    name="recording"
                                    value={values.recording}
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}
                                    label={i18n.generic.recording}
                                    disabled={
                                        !selectedRoom || !selectedRoom.recording
                                    }
                                />
                                <Checkbox
                                    errors={errors}
                                    touched={touched}
                                    name="liveStream"
                                    value={values.liveStream}
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}
                                    label={i18n.generic.liveStreaming}
                                    disabled={
                                        !selectedRoom ||
                                        !selectedRoom.livestreaming
                                    }
                                />
                            </div>
                            {/* Show notice when changing from livestream to no livestream. */}
                            <ShowIfView
                                condition={
                                    originalLivestream && !values.liveStream
                                }
                            >
                                <FormNotice>
                                    {
                                        i18n.ceremonyModal
                                            .streamingCeremonyDisabled
                                    }
                                </FormNotice>
                            </ShowIfView>
                        </div>
                        <ShowIfView condition={selectedRoom.signageEnabled}>
                            <label className="label">
                                {i18n.generic.signage}
                            </label>
                            <div>
                                <div className={styles.checkboxContainer}>
                                    <Checkbox
                                        errors={errors}
                                        touched={touched}
                                        name="signageEnabled"
                                        value={values.signageEnabled}
                                        setFieldValue={setFieldValue}
                                        handleBlur={handleBlur}
                                        label={
                                            i18n.roomModal.signageEnabledLabel
                                        }
                                        reversed
                                    />
                                </div>
                                <Input
                                    errors={errors}
                                    touched={touched}
                                    name="signageInCollaborationWith"
                                    label=""
                                    placeholder={
                                        i18n.generic.inCollaborationWith
                                    }
                                    smallerMargin
                                />
                            </div>
                        </ShowIfView>
                    </Form>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={submitForm}
                        disabled={isSubmitting}
                        className="button is-primary is-rounded"
                    >
                        {isEditing ? i18n.generic.save : i18n.generic.create}
                    </button>
                    <ShowIf condition={isEditing}>
                        <button
                            disabled={isDeleteDisabled}
                            onClick={() =>
                                deleteCeremony(values.id, values.crematoryId)
                            }
                            className="button is-danger is-rounded"
                        >
                            {i18n.ceremonyModal.deleteButtonLabel}
                        </button>
                    </ShowIf>
                </footer>
            </div>
        </div>
    );
};

export default CrematoryModalView;
