const roles = {
    admin: "Admin",
    crematory: "Crematorium",
    endUser: "EndUser",
    organization: "Organization"
};

export type Role = typeof roles[keyof typeof roles];

export default roles;
