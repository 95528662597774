import React, { useEffect, useState } from "react";
import styles from "./BlockCommentsInput.module.scss";
import ShowIf from "../../components/ShowIf";
import { BlockCommentsDeleteButton } from "./button/BlockCommentsDeleteButton";
import { useDispatch } from "react-redux";
import { showToast } from "../../actions/toasts";
import { useSaveBlockComment } from "./hooks/useSaveBlockComment";
import { BlockComment } from "./types/BlockComment";
import i18n from "../../constants/i18n";

interface BlockCommentsInputProps {
    ceremonyId: number;
    blockId: number;
    comment: BlockComment;
    onCommentSaved: (index: number, content: BlockComment) => void;
    removeComment: (index: number) => void;
    commentIndex: number;
}

export function BlockCommentsInput(props: BlockCommentsInputProps) {
    const [editable, setEditable] = useState(!props.comment.id);
    const [content, setContent] = useState<string | null>(
        props.comment.content || null
    );
    const dispatch = useDispatch();
    const saveComment = useSaveBlockComment(
        props.ceremonyId,
        props.blockId,
        props.comment.id,
        onCommentSaved
    );

    async function onCommentSaved(content: BlockComment) {
        dispatch(
            showToast({
                body: i18n.blockComment.saved,
                title: i18n.generic.success,
                themeClass: "is-success",
                preventDismiss: false,
            })
        );
        props.onCommentSaved(props.commentIndex, content);
        setEditable(false);
    }

    useEffect(() => {
        setContent(props.comment.content);
    }, [props.comment.content]);

    return (
        <div key={props.comment.id} style={{ marginBottom: "10px" }}>
            <>
                <ShowIf condition={props.comment.author}>
                    <span>{props.comment.author}:</span>
                </ShowIf>
                <p className="control has-icons-left has-icons-right">
                    <textarea
                        className={`textarea ${
                            editable ? "is-info" : "is-secondary"
                        } ${styles.textarea}`}
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        placeholder={i18n.blockComment.placeholder}
                        readOnly={!editable}
                    />
                </p>
                <div className={styles.footer}>
                    <div>
                        <ShowIf condition={!editable}>
                            <button
                                onClick={() => {
                                    setEditable(true);
                                }}
                                className="button is-text is-rounded is-small"
                            >
                                {i18n.generic.edit}
                            </button>
                            <BlockCommentsDeleteButton
                                comment={props.comment}
                                ceremonyId={props.ceremonyId}
                                blockId={props.blockId}
                                blockCommentId={props.comment.id}
                                onCommentRemoved={props.removeComment}
                                commentIndex={props.commentIndex}
                            />
                        </ShowIf>
                        <ShowIf condition={editable}>
                            <button
                                onClick={() => {
                                    setContent(content?.trim());
                                    saveComment.handleCommentSave(
                                        content?.trim()
                                    );
                                }}
                                className="button is-primary is-rounded is-small"
                                disabled={
                                    saveComment.isLoading ||
                                    !content ||
                                    !content?.trim()
                                }
                            >
                                {i18n.generic.save}
                            </button>
                            <button
                                onClick={() => {
                                    setEditable(false);
                                    if (!props.comment.id) {
                                        props.removeComment(props.commentIndex);
                                    }
                                }}
                                className="button is-secondary is-rounded is-small"
                            >
                                {i18n.generic.cancel}
                            </button>
                        </ShowIf>
                    </div>
                </div>
            </>
        </div>
    );
}
