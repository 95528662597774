import React from "react";
import styles from "./PlaybookModal.module.scss";
import i18n from "../constants/i18n";
import Tippy from "@tippy.js/react";
import { RoundIconButton } from "../components/TimelineOverview/RoundIconButton";
import { PlaybookModalContent } from "./PlaybookModalContent";

interface PlaybookModalProps {
    ceremonyId: number;
    familyName: string;
}

export function PlaybookModal(props: PlaybookModalProps) {
    const [isVisible, setIsVisible] = React.useState(false);

    return (
        <>
            <div className={`modal ${isVisible ? "is-active" : ""}`}>
                <div className="modal-background" />
                <div className={`modal-card ${styles.playbookModal}`}>
                    <header className="modal-card-head">
                        <h2 className="modal-card-title has-text-primary">
                            Draaiboek
                        </h2>
                        <button
                            onClick={() => setIsVisible(false)}
                            className="delete"
                            aria-label="close"
                        />
                    </header>
                    {isVisible && (
                        <PlaybookModalContent
                            ceremonyId={props.ceremonyId}
                            familyName={props.familyName}
                        />
                    )}
                </div>
            </div>
            <RoundIconButton onClick={() => setIsVisible(true)}>
                <Tippy content={i18n.generic.downloadScript}>
                    <span className="icon tutorial-step-8">
                        <i className="fas fa-book-open" />
                    </span>
                </Tippy>
            </RoundIconButton>
        </>
    );
}
