import Tippy from "@tippy.js/react";
import { format, parse } from "date-fns";
import React from "react";
import Joyride from "react-joyride";
import { withRouter } from "react-router-dom";

import i18n from "../../constants/i18n";
import JoyrideTooltip from "../JoyrideTooltip";
import PollCeremonyAssets from "../PollCeremonyAssets";
import PollCeremonyLockStatus from "../PollCeremonyLockStatus";
import ShowIf from "../ShowIf";
import Warning from "../Warning";
import BlockRemovalArea from "./BlockRemovalArea";
import Blocks from "./Blocks";
import DragDropRoot from "./DragDropRoot";
import DroppableBlockRemoval from "./DroppableBlockRemoval";
import Photos from "./Photos";
import styles from "./TimelineOverview.module.scss";
import { PlaybookModal } from "../../playbook/PlaybookModal";
import { RoundIconButton } from './RoundIconButton';
import { useDebounce } from "../../core/hooks/useDebounce";

const TimelineOverviewView = props => {
    const [debouncedBlockPersist, pause, resume] = useDebounce(
        props.persistBlockMove,
        1000
    );

    async function handleBlockMove(sourceIndex, destinationIndex) {
        const updatedBlocks = await props.onBlockMoved(
            sourceIndex,
            destinationIndex
        );
        debouncedBlockPersist(updatedBlocks);
    }

    return (
        <div>
            <Joyride
                callback={props.onIntroCallback}
                continuous={true}
                run={props.tutorialOptions.run}
                scrollToFirstStep={true}
                showSkipButton={true}
                spotlightPadding={12}
                steps={props.tutorialOptions.steps}
                tooltipComponent={JoyrideTooltip}
            />
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <div className={` ${styles.backButton}`}>
                            <div onClick={props.onBackToOverviewButtonClick}>
                                <span className="icon is-small">
                                    <i className="fas fa-chevron-left" />
                                </span>
                                <span>{i18n.generic.backToOverview}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="level-right">
                    <div className={`level-item ${styles.roundIconsContainer}`}>
                        <RoundIconButton onClick={props.onStartIntro}>
                            <Tippy
                                content={i18n.timelineOverview.startTutorial}
                            >
                                <span className="icon tutorial-step-7">
                                    <i className="fas fa-question" />
                                </span>
                            </Tippy>
                        </RoundIconButton>
                        <PlaybookModal
                            familyName={props.ceremony.item.familyName}
                            ceremonyId={props.ceremony.item.id}
                        />
                    </div>
                    <ShowIf condition={props.ceremonyMainImageUrl}>
                        <div className="level-item">
                            <button
                                onClick={props.onMainImageButtonClick}
                                className="button is-rounded is-primary tutorial-step-6"
                            >
                                <div
                                    className={styles.mainImage}
                                    style={{
                                        backgroundImage: `url(${props.ceremonyMainImageUrl})`
                                    }}
                                ></div>
                                <span className={styles.mainImageButtonLabel}>
                                    {i18n.timelineOverview.mainPicture}
                                </span>
                            </button>
                        </div>
                    </ShowIf>
                    <ShowIf condition={!props.ceremonyMainImageUrl}>
                        <div className="level-item">
                            <button
                                onClick={props.onMainImageButtonClick}
                                className="button is-rounded is-primary tutorial-step-6"
                            >
                                <span className="icon is-small">
                                    <i className="fas fa-image"></i>
                                </span>
                                <span className={styles.mainImageButtonLabel}>
                                    {i18n.timelineOverview.selectMainPicture}
                                </span>
                            </button>
                        </div>
                    </ShowIf>
                    <div className="level-item">
                        <button
                            onClick={props.onRelativesButtonClick}
                            className="button is-rounded is-primary tutorial-step-9"
                        >
                            <div className={styles.relativesCount}>
                                <span>
                                    {props.ceremony.item.relatives.length}
                                </span>
                            </div>
                            <span className={styles.relativesButtonLabel}>
                                {
                                    i18n.timelineOverview
                                        .relativesInvitationButtonLabel
                                }
                            </span>
                            <span className="icon is-small">
                                <i className="fas fa-user-plus"></i>
                            </span>
                        </button>
                    </div>
                    <div className="level-item">
                        <button
                            onClick={props.onPreviewButtonClick}
                            className="button is-rounded is-primary tutorial-step-10"
                        >
                            {i18n.timelineOverview.preview}
                        </button>
                    </div>
                </div>
            </div>
            {props.isFetching ? (
                <div className={`is-relative ${styles.loaderContainer}`}>
                    <div className="loader" />
                </div>
            ) : (
                <DragDropRoot
                    blocksLength={props.blocks.length}
                    onBlockMoved={handleBlockMove}
                    onBlockRemoved={props.onBlockRemoved}
                    onPhotosAttachedToBlock={props.onPhotosAttachedToBlock}
                    onDragStateChange={isDragging => {
                        isDragging ? pause() : resume();
                    }}
                >
                    <PollCeremonyAssets ceremonyId={props.ceremony.item.id} />
                    <PollCeremonyLockStatus
                        ceremonyId={props.ceremony.item.id}
                    />
                    <Photos
                        showPhotoUploadModal={props.showPhotoUploadModal}
                        toggleBlockReordering={props.toggleBlockReordering}
                        deletePhoto={props.deletePhoto}
                        ceremonyId={props.ceremony.item.id}
                        ceremony={props.ceremony}
                        blocks={props.blocks}
                        useBlurredPhotoBackground={
                            props.ceremony.item.useBlurredPhotoBackground
                        }
                        onBlurredBackgroundClick={
                            props.onBlurredBackgroundClick
                        }
                        blurredBackgroundEnabledCheck={
                            props.blurredBackgroundEnabledCheck
                        }
                    />
                    <div className={styles.ceremonyLabel}>
                        <h2>
                            {i18n.timelineOverview.timeline}{" "}
                            {props.ceremony.item.familyName} &middot;{" "}
                            {format(
                                parse(
                                    props.ceremony.item.date,
                                    "yyyy-MM-dd HH:mm:ss",
                                    new Date()
                                ),
                                "dd/MM/yyyy"
                            )}{" "}
                            &middot;{" "}
                            {format(
                                parse(
                                    props.ceremony.item.date,
                                    "yyyy-MM-dd HH:mm:ss",
                                    new Date()
                                ),
                                "HH:mm"
                            )}
                        </h2>
                    </div>
                    <Blocks
                        onAddButtonClick={props.onAddButtonClick}
                        onEditButtonClick={props.onEditBlock}
                        onWalkInOrOutBlockMediaPlaceholderClick={
                            props.onWalkInOrOutBlockMediaPlaceholderClick
                        }
                        isBlockReorderModeEnabled={
                            props.isBlockReorderModeEnabled
                        }
                        hasExceededCeremonyDuration={
                            props.hasExceededCeremonyDuration
                        }
                    />
                    <DroppableBlockRemoval
                        render={snapshot => (
                            <BlockRemovalArea
                                isDraggingOver={snapshot.isDraggingOver}
                            />
                        )}
                    />
                </DragDropRoot>
            )}
            <ShowIf condition={props.error}>
                <Warning>{i18n.generic.error}</Warning>
            </ShowIf>
        </div>
    );
};

export default withRouter(TimelineOverviewView);
